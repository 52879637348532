<template>
  <div class="d-flex code-copy">
    <v-text-field
      class="code-copy__content"
      :value="value"
      dense
      outlined
      hide-details
      readonly
    />
    <v-btn
      small
      icon
      :title="buttonTitle"
      v-clipboard:copy="value"
      v-clipboard:success="() => $notify.success({ title: 'Скопировано в буфер обмена' })"
    >
      <v-icon small>fa-copy</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    buttonTitle: {
      type: String,
      default: 'Копировать'
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.code-copy {
  display: flex;
  grid-gap: 2px;
  align-items: center;
}
</style>
