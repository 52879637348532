<template>
  <item-list-view
    ref="list"
    :api="api"
    :headers="headers"
    id-key="id"
    title="Фотопоток"
    :allowed-search="true"
    :allowed-create="false"
    :allowed-remove="true"
    update-route="Photo"
    remove-title="Удаление фотографии"
    remove-message="Вы действительно хотите удалить фотографию?"
  >
    <template v-slot:before-table>
      <v-flex style="width: 100%">
        <ImagesUploader @success="fetchDebounce" />
      </v-flex>
    </template>

    <template v-slot:item.image="{ item }">
      <div style="max-width: 150px;">
        <img
          :src="item.thumbnailUrl"
          style="max-width: 100%"
        />
      </div>
    </template>

    <template v-slot:item.title="{ item }">
      <div>
        <div v-if="item.title" class="text-body-1 mb-2">{{ item.title }}</div>
        <!-- <div>{{ item.id }}</div> -->
        <v-code :value="item.url" style="max-width: 320px">
          {{ item.url }}
        </v-code>
        <!-- <div class="d-flex">
          <div style="max-width: 320px; overflow-x: auto">
            <div style="; font-family: monospace; white-space: nowrap">
              {{ item.url }}
            </div>
          </div>
          <v-btn icon small v-clipboard:copy="item.url">
            <v-icon small>fa-copy</v-icon>
          </v-btn>
        </div> -->

      </div>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
  </item-list-view>
</template>

<style lang="scss">

</style>

<script>
import VCode from '@/components/elements/VCode.vue'
import api from '../../services/photos'
import ItemListView from '../../components/ItemListView'
import debounce from 'lodash/debounce'
import ImagesUploader from '../../components/elements/upload/ImagesUploader.vue'

export default {
  components: {
    VCode,
    ItemListView,
    ImagesUploader
  },
  data: () => ({
    api: api,
    headers: [
      { text: 'Изображение', value: 'image' },
      { text: 'Название', value: 'title' },
      { text: 'Дата загрузки', value: 'createdAt' },
      { text: '', value: 'action' }
    ],
  }),
  methods: {
    fetchDebounce: debounce(function() {
      this.$refs.list.searchDebounce()
    }, 500)
  }
}
</script>
